<script>
	// @ts-nocheck
	import { MultiSelect, TextInput, Toggle } from 'carbon-components-svelte';
	import { onMount } from 'svelte';
	import { CLASSROOMS_ALL } from '$lib/stores/stores';

	// Usage Guide
	$: data = $CLASSROOMS_ALL;
	export let selectedClassrooms;
	export let multiple = selectedClassroomsArr.length > 1 ? true : false;
	export let placeholder = 'SELECT CLASS';

	// $: console.log('data______', data);
	// $: console.log('selectedClassroomsArr______', selectedClassroomsArr);
	// $: console.log('selectedClassrooms______', selectedClassrooms);

	// let selectedClassroomsArr = selectedClassrooms.map((x) => {
	// 	return { id: x, name: x, checked: true };
	// });
	let selectedClassroomsArr = [];

	let classroomsOrigin = [];

	let classrooms = [];
	let allClassrooms = { id: 0, name: 'All Classrooms', checked: false };
	let showDropdown = false;

	onMount(async () => {});

	const populateClassrooms = async (d) => {
		let sortedData = await d.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
		classroomsOrigin = await sortedData.map((x) => {
			return { ...x, checked: false };
		});
		classrooms = [...classroomsOrigin];

		// preSelected options
		classrooms = classrooms.map((c) => {
			if (selectedClassrooms.includes(c.name)) {
				c.checked = true;
			}
			return c;
		});

		selectedClassroomsArr = selectedClassrooms.map((x) => {
			let c = classrooms.filter((c) => c.name.toLowerCase() === x.toLowerCase())[0];
			return { ...c };
		});

		showDropdown = true;
	};

	const removeFromSelection = (id, array) => {
		let removeObjIndex = classrooms.findIndex((obj) => obj.id == id);
		array[removeObjIndex].checked = false;
	};

	const addToSelection = (id, array) => {
		let addObjIndex = classrooms.findIndex((obj) => obj.id == id);
		array[addObjIndex].checked = true;
	};

	const handleClick = async ({ target }) => {
		let newId = parseInt(target.id.replace('x', ''), 10);
		if (allClassrooms.id == newId && allClassrooms.checked == true) {
			// uncheck all classrooms
			setTimeout(() => (target.checked = false), 0);
			allClassrooms.checked = false;
			// reset checkboxes
			selectedClassroomsArr = [];
			classrooms.map((o) => {
				removeFromSelection(o.id, classrooms);
				window.$(`#${o.id}x`).prop('checked', false);
			});
			// check first classroom
			addToSelection(classrooms[0].id, classrooms);
			window.$(`#${classrooms[0].id}x`).prop('checked', true);
			handleChange(newId);
			selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
			return;
		}
		if (allClassrooms.id == newId && allClassrooms.checked == false) {
			setTimeout(() => (target.checked = true), 0);
			allClassrooms.checked = true;
			multiple = true;
			// remove all previously selected
			selectedClassroomsArr = [];
			classrooms.map((o) => {
				removeFromSelection(o.id, classrooms);
				window.$(`#${o.id}x`).prop('checked', false);
			});
			// add all classes
			classrooms.map((o) => {
				addToSelection(o.id, classrooms);
				window.$(`#${o.id}x`).prop('checked', true);
			});
			handleChange(newId);
			selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
			return;
		}
		if (multiple === true) {
			if (selectedClassroomsArr.find((o) => o.id === newId)) {
				// remove current selected
				setTimeout(() => (target.checked = false), 0);
				removeFromSelection(newId, classrooms);
				handleChange(newId);
				selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
				return;
			} else {
				setTimeout(() => (target.checked = true), 0);
				addToSelection(newId, classrooms);
				handleChange(newId);
				selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
				return;
			}
		} else {
			if (selectedClassroomsArr.find((o) => o.id === newId)) {
				// remove current selected
				setTimeout(() => (target.checked = false), 0);
				removeFromSelection(newId, classrooms);
				handleChange(newId);
				selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
				return;
			}
			if (selectedClassroomsArr.length === 0) {
				// only add
				setTimeout(() => (target.checked = true), 0);
				addToSelection(newId, classrooms);
				handleChange(newId);
				selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
				return;
			} else {
				// remove previously added and add current
				selectedClassroomsArr = [];
				classrooms.map((o) => {
					removeFromSelection(o.id, classrooms);
					window.$(`#${o.id}x`).prop('checked', false);
				});
				setTimeout(() => (target.checked = true), 0);
				addToSelection(newId, classrooms);
				handleChange(newId);
				selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
				return;
			}
		}
	};

	const handleChange = (id) => {
		let checkedClassrooms = classrooms.filter((classroom) => classroom.checked === true);
		selectedClassroomsArr = [...checkedClassrooms];
	};

	$: {
		if (!multiple && selectedClassroomsArr.length > 1) {
			let lastObj = selectedClassroomsArr.slice(-1)[0];
			selectedClassroomsArr.map((o) => {
				if (o.id !== lastObj.id) {
					removeFromSelection(o.id, classrooms);
					window.$(`#${o.id}x`).prop('checked', false);
				}
			});
			selectedClassroomsArr = [lastObj];
			selectedClassrooms = selectedClassroomsArr.map((a) => a.name);
		}
	}

	const makeLabel = (arr) => {
		if (arr.length === 1) {
			return arr[0].name ? arr[0].name : 'SELECT CLASS';
		} else {
			return arr[0].name ? `${arr[0].name} + ${arr.length - 1} more` : 'SELECT CLASS';
		}
	};

	const filterClassrooms = ({ target: { value } }) => {
		if (value === '') {
			classrooms = [...classroomsOrigin];
			return;
		}
		let x = [...classroomsOrigin];
		classrooms = x.filter((classroom) =>
			classroom.name.toLowerCase().includes(value.toLowerCase())
		);
	};

	const resetScroll = () => {
		document.getElementById('list-group-section').scrollTop = 0;
	};

	$: populateClassrooms(data);
</script>

<svelte:head>
	<script>
		// $(document).ready(function () {
		// 	$('.dropdown-menu').on('click', function (e) {
		// 		e.stopPropagation();
		// 	});
		// });
	</script>
</svelte:head>

<!-- svelte-ignore a11y-missing-attribute -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="dropdown-wrapper">
	{#if data && data.length > 0 && showDropdown}
		<div class="dropdown dropdown-keep-open m-0" on:click={() => resetScroll()}>
			<a
				href="#button"
				data-flip="false"
				type="button"
				class="btn btn-special rounded-0 px-3 text-white py-2 dropdown-toggle-carbon"
				data-toggle="dropdown"
			>
				{selectedClassroomsArr.length > 0 ? makeLabel(selectedClassroomsArr) : 'SELECT CLASS'}
			</a>
			<div class="dropdown-menu p-0" on:click={(e) => e.stopPropagation()}>
				<div class="card widget-5" style="max-height: 70vh">
					<div class="card-header" style="">
						<Toggle
							class="form-toggle-1"
							size="sm"
							labelA=""
							labelB=""
							labelText="Select Multiple"
							bind:toggled={multiple}
						/>
					</div>
					<div
						class="list-group-item py-1 rounded-top-0 search-classroom"
						id="searchclassroom"
						style=""
					>
						<div class="media">
							<div class="media-body pt-0" id="1">
								<TextInput placeholder="Find Classroom" on:keyup={filterClassrooms} />
							</div>
						</div>
					</div>
					<div class="list-group carbon" style="" id="list-group-section">
						<div class="list-group-item carbon">
							<div class="media pl-2">
								<div class="bx--form-item bx--checkbox-wrapper">
									<input
										type="checkbox"
										bind:checked={allClassrooms.checked}
										on:change={(e) => handleChange(allClassrooms.id)}
										on:click|preventDefault={handleClick}
										id={allClassrooms.id.toString() + 'x'}
										name=""
										class="bx--checkbox"
									/>
									<label for={allClassrooms.id.toString() + 'x'} class="bx--checkbox-label">
										<span class="bx--checkbox-label-text">
											{allClassrooms.name}
										</span>
									</label>
								</div>
							</div>
						</div>
						<!---->
						{#each classrooms as classroom, index (classroom.id)}
							<div class="list-group-item carbon">
								<div class="media pl-2">
									<div class="bx--form-item bx--checkbox-wrapper">
										<input
											type="checkbox"
											bind:checked={classroom.checked}
											on:change={(e) => handleChange(classroom.id)}
											on:click|preventDefault={handleClick}
											id={classroom.id.toString() + 'x'}
											name=""
											class="bx--checkbox"
										/>
										<label for={classroom.id.toString() + 'x'} class="bx--checkbox-label">
											<span class="bx--checkbox-label-text">
												{classroom.name}
											</span>
										</label>
									</div>
								</div>
							</div>
						{/each}
					</div>
				</div>
			</div>
		</div>
	{:else}
		<div class="dropdown dropdown-keep-open m-0">
			<a
				href="#button"
				data-flip="false"
				type="button"
				class="btn btn-special rounded-0 px-3 text-white py-2 dropdown-toggle-carbon"
			>
				{selectedClassroomsArr.length > 0 ? makeLabel(selectedClassroomsArr) : placeholder}
			</a>
		</div>
	{/if}
	<!-- {/await} -->
</div>

<style>
	.dropdown-wrapper,
	.dropdown {
		height: 2.5rem;
		max-height: 2.5rem;
	}

	.dropdown > a {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.dropdown-keep-open > a:focus {
		box-shadow: none;
	}

	.dropdown-toggle-carbon::after {
		content: '';
		display: inline-block;
		margin-left: 20px;
		width: 0.5rem;
		height: 0.5rem;
		border: 0;
		border-bottom: 1px solid currentColor;
		border-left: 1px solid currentColor;
		transform: rotate(-45deg) translate(30%, 0%);
		transform-origin: center center;
		transition: transform ease-in-out 100ms;
	}

	:global(.dropdown.show .dropdown-toggle-carbon::after) {
		transform: rotate(-225deg) translate(0%, 0%);
	}

	.dropdown-menu {
		box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
		min-width: 200px; /* just added *?
		/* position: absolute; */
		z-index: 9100;
		/* right: 0;
    left: 0;
    width: 100%; */
		background-color: #ffffff;
		transform: translate3d(0px, 38px, 0px) !important;
		border-radius: 0px;
		border: 0;
		padding: 0;
		width: 100%;
		/* overflow-y: auto; */
		/* transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9); */
	}

	.dropdown-menu .card,
	.dropdown-menu .card .list-group {
		background-color: #ffffff;
	}

	.dropdown-menu .card-header {
		display: flex;
		align-items: center;
		border-radius: 0px;
		background-color: #ffffff;
		justify-content: space-between;
		padding: 10px;
	}

	.dropdown-menu .search-classroom {
		padding: 5px 5px;
		background-color: #ffffff;
		border-radius: 0px;
		border: 0;
	}

	/* .media .mychkbox {
		cursor: pointer;
	} */
	.media {
		display: flex;
		align-items: center;
	}
	.media-body {
		padding-top: 12px;
	}

	/* .media-body label {
		text-decoration: none;
		cursor: pointer;
		color: #000;
		font-weight: 400;
	} */

	/* Items Container */
	.list-group.carbon {
		height: auto;
		max-height: 470px;
		height: auto;
		max-height: 470px;
		width: 100%;
		overflow-y: auto;
		transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
		padding-top: 0px;
	}

	/* Items */
	.list-group-item.carbon {
		border-top-color: rgba(0, 0, 0, 0);
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.28572;
		letter-spacing: 0.16px;
		position: relative;
		height: 2.5rem;
		color: #525252;
		cursor: pointer;
		transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
		padding: 5px 2px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: #ffffff;
		border-radius: 0px;
	}

	/* .onoffswitch {
    display: flex;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    margin: 0px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    top: 1px;
    left: 1px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  } */

	/* Rounded sliders */
	/* .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  } */

	/* Anchor Button */
	.btn-special {
		color: #fff;
		background-color: #0f62fe;
		border-color: #0f62fe;
		font-weight: 500;
		font-size: 0.775rem;
	}
	.btn-special:hover {
		/* background-color: #e5e5e5; */
		border: 1px solid #fff;
		border-bottom: 1px solid #8d8d8d;
		background-color: #f4f4f4;
		color: #161616 !important;
	}
	:global(.dropdown.show .btn-special) {
		border: 1px solid #fff;
		border-bottom: 1px solid #8d8d8d;
		background-color: #f4f4f4;
		color: #161616 !important;
	}

	/* Toogle */
	:global(.form-toggle-1 .bx--toggle-input__label) {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0px !important;
		width: 100%;
	}

	:global(.form-toggle-1 .bx--toggle-input__label span) {
		font-size: 12px !important;
		font-weight: 400 !important;
	}

	:global(.form-toggle-1 .bx--toggle__switch) {
		margin-top: 0px !important;
		margin-left: 5px;
	}

	/* Text Input */
	:global(.bx--form-item .bx--text-input) {
		background-color: #ffffff;
		font-size: 12px;
		outline: 0;
	}
</style>

export const prerender = 'auto';
// export const ssr = false;

import { browser } from '$app/environment';
import { getFromLocalStorage } from '$lib/utils/requestUtils';
import { checkUserAgentasMobile, sleep } from '$lib/utils/helpers';
import { TOKEN_CHECK, TOKEN } from '$lib/stores/stores';

import { CHECK_TOKEN } from '$lib/dataproviders/getSettings';
import { showSite } from '$lib/utils/theme';
import { get } from 'svelte/store';

export const load = async ({ url }) => {
	if (browser) {
		await sleep(1200);

		// if (getFromLocalStorage('token') !== null) {
		const userToken = get(TOKEN) ?? null;
		if (userToken && userToken.token) {
			// check if the token age is greater than 20 minutes
			const current_time = new Date().getTime();

			if (current_time - userToken.age > 1200000) {
				// token might be expired
				await TOKEN_CHECK.set(true);

				// check if user token is valid
				const isTokenValid = await CHECK_TOKEN();

				if (!isTokenValid) {
					// set token store

					const current_url = (await getFromLocalStorage('CURRENT_URL')) || '';
					if (url.pathname !== '/') {
						showSite();

						return {
							isLoggedIn: false,
							showError: true,
							route: url.pathname,
							current_url: current_url
						};
					} else {
						showSite();

						return {
							isLoggedIn: false,
							showError: false,
							route: url.pathname,
							current_url: current_url
						};
					}
				}
			}

			const user_type = (await JSON.parse(getFromLocalStorage('USER_DETAILS'))?.user_type) || '';
			const current_url = (await getFromLocalStorage('CURRENT_URL')) || '';

			if (
				user_type.toLowerCase() !== 'parent' &&
				url.pathname.includes('/parents') &&
				!url.pathname.includes('/users')
			) {
				window.location.href = '/';
			} else if (user_type.toLowerCase() === 'parent' && !url.pathname.includes('/parents')) {
				window.location.href = '/parents/students';
			} else if (
				checkUserAgentasMobile() &&
				user_type.toLowerCase() !== 'parent' &&
				!url.pathname.includes('/mobile/')
			) {
				window.location.href = '/mobile/scoresheet';
			} else {
				showSite();
				return {
					isLoggedIn: true,
					showError: false,
					route: url.pathname,
					user_type: user_type,
					current_url: current_url,
					isMobile: checkUserAgentasMobile()
				};
			}
		} else {
			// if (getFromLocalStorage('token') === null) {
			const current_url = (await getFromLocalStorage('CURRENT_URL')) || '';
			if (url.pathname !== '/') {
				showSite();
				return {
					isLoggedIn: false,
					showError: true,
					route: url.pathname,
					current_url: current_url
				};
			} else {
				showSite();
				return {
					isLoggedIn: false,
					showError: false,
					route: url.pathname,
					current_url: current_url
				};
			}
			// }
		}
	}
};

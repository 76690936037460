<script>
	import schoolMoDark from '$lib/images/schoolmo4_.png';
	import User from 'carbon-icons-svelte/lib/User.svelte';
	import Settings from 'carbon-icons-svelte/lib/Settings.svelte';
	import { Tooltip, TooltipDefinition } from 'carbon-components-svelte';
	import Logout from 'carbon-icons-svelte/lib/Logout.svelte';
	import { CURRENT_URL, ERROR_MESSAGE, USER_DETAILS, SCHOOL_NAME } from '$lib/stores/stores';

	import { LOGIN_TYPE } from '$lib/stores/stores';
	import Currency from 'carbon-icons-svelte/lib/Currency.svelte';

	export let parent;

	const logout = async () => {
		// LOGIN_TYPE.set($USER_DETAILS.user_type.toLowerCase());
		await USER_DETAILS.set(null);
		await ERROR_MESSAGE.set('Logged Out!');
		await window.localStorage.removeItem('token');
		await window.localStorage.removeItem('age');
		await CURRENT_URL.set('');
		window.location.href = $LOGIN_TYPE === 'parent' ? '/parents' : '/';
	};

	/* // TESTING PURPOSES
	onMount(async () => {
		await USER_DETAILS.update((prev) => {
			const school = {
				...prev.school[0],
				billing_cost: 1000,
				bankname: 'GTB',
				account_number: '1234567890',
				account_name: `SCHOOLMO / ${prev.school[0].name}`
			};
			return {
				...prev,
				school: [school],
				// credits: [0, Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), null][
				// 	Math.floor(Math.random() * 4)
				// ]
				credits: 100
			};
		});
	}); */
</script>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<div class="container-fluid">
		<div class="header-start">
			<span id="sidebarCollapse">
				<i class="material-icons" style="font-size:36px;">menu</i>
			</span>
			<div class="header-brand">
				<div style="display: flex; flex-direction:row; align-items:center; justify-content:center">
					<img src={schoolMoDark} srcset={schoolMoDark} alt="schoolmo" style="max-height:36px" />
				</div>
				<!-- <img src={schoolMo} srcset={schoolMo} alt="schoolmo" style="max-height:24px" />		 -->
			</div>
		</div>

		<div class="header-title">
			<div class="news-text" style="text-transform: uppercase;">
				{$SCHOOL_NAME}
			</div>
		</div>
		<div class="header-wrap">
			<div class="header-tools">
				<ul class="quick-nav">
					<!-- 0 should show  but null should not -->
					{#if $USER_DETAILS && $USER_DETAILS.school[0].credits !== null && !parent && ($USER_DETAILS.user_type.toLowerCase() === 'super admin' || $USER_DETAILS.user_type.toLowerCase() === 'admin')}
						<TooltipDefinition
							direction="bottom"
							align="center"
							tooltipText="Credits Available"
							class="credit-tooltip"
						>
							<li style="margin-right: 10px;">
								<a href="/credits" class="credit-wrapper">
									<Currency size={35} class="credit-custom-class" />
									<h3 class="news-text">
										{`${$USER_DETAILS ? $USER_DETAILS.school[0].credits : 0}`}
									</h3>
								</a>
							</li>
						</TooltipDefinition>
					{/if}

					<!-- svelte-ignore a11y-invalid-attribute -->
					<li class="dropdown user-dropdown">
						<a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
							<div class="user-toggle">
								<div class="user-avatar sm"><User size={20} /></div>
								<div class="user-info d-none d-md-block">
									{#if !parent}
										<div class="user-status">
											{`${$USER_DETAILS ? $USER_DETAILS.user_type : ''}`}
										</div>
										<div class="user-name dropdown-indicator">
											{`${$USER_DETAILS ? $USER_DETAILS.fullname : ''}`}
										</div>
									{:else}
										<div class="user-name dropdown-indicator" style="padding-right: 5px;">
											{`${$USER_DETAILS ? $USER_DETAILS.username : ''}`}
										</div>
									{/if}
								</div>
							</div></a
						>
						<div
							class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1"
							style=""
						>
							{#if !parent}
								<div class="dropdown-inner">
									<ul class="link-list">
										<li>
											<a href="/settings"><Settings size={16} /><span>Settings</span></a>
										</li>
									</ul>
								</div>
							{/if}
							<div class="dropdown-inner">
								<ul class="link-list">
									<li>
										<a href="#" on:click={() => logout()}>
											<Logout size={16} />
											<span>Sign out</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<!-- .dropdown -->
				</ul>
				<!-- .nk-quick-nav -->
			</div>
		</div>
	</div>
</nav>

<style>
	.credit-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	h3.news-text {
		font-weight: 300;
		line-height: 1.1;
		font-size: 1.7rem;
		margin: 0;
		padding: 0 4px;
	}

	:global(svg.credit-custom-class) {
		fill: #0f62fe;
	}

	:global(.credit-tooltip .bx--tooltip--a11y) {
		border: none;
	}
</style>

<script>
	export let returnUrl = null;
	export let currentUrl = null;
	export let userType = '';
	import { browser } from '$app/environment';
	import '$lib/styles/style.css';
	import { page } from '$app/stores';
	import { LOGIN, PARENT_LOGIN } from '$lib/dataproviders/auth';
	import {
		FONT_CHECKER,
		UNIVERSAL_ERROR_MESSAGE,
		UNIVERSAL_SUCCESS_MESSAGE,
		USER_DETAILS,
		SCHOOL_NAME,
		LOGIN_TYPE
	} from '$lib/stores/stores';
	import { onMount } from 'svelte';
	import { fly } from 'svelte/transition';
	import { toastNotify } from '@components/toast';
	import schoolMo_dark_ from '$lib/images/schoolmo4_.png';
	import { PARENT_INFO } from '$lib/stores/parent';
	import {
		Button,
		Column,
		Form,
		Grid,
		Loading,
		PasswordInput,
		Row,
		TextInput,
		LocalStorage,
		Modal
	} from 'carbon-components-svelte';
	import Login from 'carbon-icons-svelte/lib/Login.svelte';
	import SwitchLayer_2 from 'carbon-icons-svelte/lib/SwitchLayer_2.svelte';
	import CloudOffline from 'carbon-icons-svelte/lib/CloudOffline.svelte';

	import { REMOVE_LEAKED_DATA } from '$lib/dataproviders/getStudents';
	import { checkUserAgentasMobile } from '$lib/utils/helpers';
	import { apiErrorHandler } from '$lib/utils/apiErrorHandler';

	let username = '';
	let password = '';
	let parentEmail = '';
	let parentPassword = '';
	let loading = false;
	let storage;
	let openConfirmCLS = false;

	let loginType = userType ? userType : $LOGIN_TYPE ?? 'staff';

	onMount(() => {});

	const login = async () => {
		loading = true;

		// clear token
		await window.localStorage.removeItem('token');
		await window.localStorage.removeItem('age');

		if (!username || !password) {
			loading = false;
			toastNotify('Username and Password Required', 'error');
			return;
		}

		const removeLeakedData = async () => {
			const callback = async (res, message) => {
				if (res) {
					console.log('res', res);
				}
			};
			const onError = async (error) => {
				error.data.message && console.error(error.data.message);
			};
			await REMOVE_LEAKED_DATA(callback, onError);
		};

		const callback = async (res, message) => {
			if (res) {
				if (browser) {
					if (res.data.data.user_type.toLowerCase() === 'parent') {
						loading = false;
						toastNotify('Invalid Login Credentials, Please use the parent login', 'error');
						return;
					}
					PARENT_INFO.set(null); // clear parent info
					USER_DETAILS.set(res.data.data);
					SCHOOL_NAME.set(res.data.data.school[0].schoolname);
					UNIVERSAL_SUCCESS_MESSAGE.set(res.data.message);
					window.localStorage.setItem('token', JSON.stringify(res.data.token));
					window.localStorage.setItem('age', new Date().getTime());

					/* not working on client 				
					// document.cookie = `token=${res.data.token}; expires=Thu, 18 Dec 2013 12:00:00 	UTC; path=/`;
					setCookie('token', res.data.token, 60 * 3); */

					if (await checkUserAgentasMobile()) {
						// if (currentUrl !== '' && currentUrl !== '/') {
						// 	window.location.href = currentUrl;
						// } else {
						window.location.href = '/mobile/scoresheet';
						// }
					}

					// remove leaked data
					// await removeLeakedData();

					FONT_CHECKER.set(true);
					if (currentUrl !== '' && currentUrl !== '/') {
						window.location.href = currentUrl;
					} else {
						if (
							returnUrl &&
							returnUrl !== '' &&
							returnUrl !== '/' &&
							returnUrl !== '/login' &&
							!returnUrl.includes('/parent')
						) {
							window.location.href = returnUrl;
						} else {
							window.location.href = '/dashboard';
						}
					}
				}
			}
		};
		const onError = (error) => {
			apiErrorHandler(error);
			loading = false;
		};

		LOGIN(username, password, callback, onError);
	};

	const loginParent = () => {
		loading = true;
		const callback = async (res) => {
			// check data returned for live data
			if (res) {
				if (browser) {
					USER_DETAILS.set({ ...res.data.data, school: res.data.school });
					PARENT_INFO.set({
						...res.data.data,
						school: res.data.school,
						students: res.data.students
					});
					SCHOOL_NAME.set(res.data.school[0].name);
					UNIVERSAL_SUCCESS_MESSAGE.set(res.data.message);
					window.localStorage.setItem('token', JSON.stringify(res.data.token));
					window.localStorage.setItem('age', new Date().getTime());
					FONT_CHECKER.set(true);
					window.location.href = '/parents/students';
				}
			}
		};
		const onError = (error) => {
			apiErrorHandler(error);
			loading = false;
		};
		PARENT_LOGIN(parentEmail, parentPassword, callback, onError);
	};

	const setLoginType = (type) => {
		if (type === 'staff' && $page.url.pathname.includes('/parents')) {
			PARENT_INFO.set(null);
			currentUrl = '/dashboard';
		} else {
			currentUrl = '/parents/students';
		}

		loginType = type;
		LOGIN_TYPE.set(type);
	};

	const clearLocalStorage = () => {
		storage.clearAll();
		toastNotify('Local Storage Cleared', 'success');
		openConfirmCLS = false;
	};

	$: {
		if ($UNIVERSAL_ERROR_MESSAGE !== '') {
			toastNotify($UNIVERSAL_ERROR_MESSAGE, 'error');
			UNIVERSAL_ERROR_MESSAGE.set('');
		}
	}
</script>

<svelte:head>
	<title>SchoolMo | Login</title>
</svelte:head>

<LocalStorage bind:this={storage} />

<div class="outer">
	<div class="middle">
		<div class="inner">
			<div
				style="display: flex; flex-direction:row; align-items:center; justify-content:center;margin-bottom:20px"
			>
				<img src={schoolMo_dark_} srcset={schoolMo_dark_} alt="SchoolMo" style="max-height:48px;" />
			</div>
			<div class="login-container">
				<Grid>
					<Row>
						<Column>
							<!-- Staff Login -->
							{#if loginType === 'staff'}
								<div in:fly={{ x: 100, duration: 500, delay: 150 }}>
									<Form
										on:submit={(e) => {
											e.preventDefault();
											login();
										}}
										novalidate
									>
										<div class="field-container">
											<div class="heading">
												<Grid>
													<Row style="margin: 10px 0px 20px 0px">
														<Column style="padding-left:0px">
															<h2 style="font-size: 1.7rem;padding: 10px 0px">Staff Login</h2>
															<span style="">Access the SchoolMo Portal</span>
														</Column>
													</Row>
													<Row>
														<Column>
															<TextInput
																labelText="Email"
																placeholder="Enter email..."
																bind:value={username}
															/>
														</Column>
													</Row>
													<Row style="margin-top: 20px">
														<Column>
															<PasswordInput
																labelText="Password"
																placeholder="Enter password.."
																bind:value={password}
															/>
														</Column>
													</Row>
													<Row style="margin-top: 20px">
														<Column>
															{#if loading}
																<div class="action">
																	<Loading withOverlay={false} small style="margin-right: 8px" />
																	<span>Please Wait</span>
																</div>
															{:else}
																<div style="display:flex;justify-content: flex-end; ">
																	<Button size="field" type="submit" icon={Login}>Login</Button>
																</div>
															{/if}
														</Column>
													</Row>
												</Grid>
											</div>
										</div>
									</Form>
								</div>
							{/if}
							<!-- Parent Login -->
							{#if loginType === 'parent'}
								<div in:fly={{ x: 100, duration: 500, delay: 150 }}>
									<Form
										on:submit={(e) => {
											e.preventDefault();
											loginParent();
										}}
										novalidate
									>
										<div class="field-container">
											<div class="heading">
												<Grid>
													<Row style="margin: 10px 0px 20px 0px">
														<Column style="padding-left:0px">
															<h2 style="font-size: 1.7rem;padding: 10px 0px">Parent Login</h2>
															<span style="">Access the SchoolMo Portal</span>
														</Column>
													</Row>
													<Row>
														<Column>
															<TextInput
																labelText="Email"
																placeholder="Enter email..."
																bind:value={parentEmail}
															/>
														</Column>
													</Row>
													<Row style="margin-top: 20px">
														<Column>
															<PasswordInput
																labelText="Password"
																placeholder="Enter password.."
																bind:value={parentPassword}
															/>
														</Column>
													</Row>
													<Row style="margin-top: 20px">
														<Column>
															{#if loading}
																<div class="action">
																	<Loading withOverlay={false} small style="margin-right: 8px" />
																	<span>Please Wait</span>
																</div>
															{:else}
																<div style="display:flex;justify-content: flex-end; ">
																	<Button size="field" type="submit" icon={Login}>Login</Button>
																</div>
															{/if}
														</Column>
													</Row>
												</Grid>
											</div>
										</div>
									</Form>
								</div>
							{/if}
						</Column>
					</Row>
				</Grid>
				<div class="bottom-link-container">
					{#if loginType === 'staff'}
						<Button
							size="field"
							kind="secondary"
							icon={SwitchLayer_2}
							disabled={loading}
							on:click={() => setLoginType('parent')}
							class="switch-layer"
						>
							{`Switch to Parent Login`}
						</Button>
					{:else}
						<Button
							size="field"
							kind="secondary"
							icon={SwitchLayer_2}
							disabled={loading}
							on:click={() => setLoginType('staff')}
							class="switch-layer"
						>
							{`Switch to Staff Login`}
						</Button>
					{/if}
					<Button
						iconDescription="Clear Local Storage"
						icon={CloudOffline}
						size="small"
						kind="secondary"
						tooltipPosition="left"
						on:click={() => (openConfirmCLS = true)}
					/>
				</div>
			</div>
		</div>
	</div>
</div>

<Modal
	size="xs"
	danger
	bind:open={openConfirmCLS}
	modalHeading={`Clear Local Storage`}
	primaryButtonText="Clear"
	secondaryButtonText="Cancel"
	on:click:button--secondary={() => (openConfirmCLS = false)}
	on:submit={() => clearLocalStorage()}
>
	<p>Are you sure you were asked to clear local storage?</p>
	<p>
		This action is <b>irreversible</b> and will clear all data stored in your browser and log you out
		of the portal.
	</p>
</Modal>

<style>
	.outer {
		display: table;
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: #ececec;
	}
	.inner {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		max-width: 430px;
		width: calc(100% - 40px);
		margin-bottom: 28px;
		min-width: 320px;
		min-height: 338px;
		overflow: hidden;
	}
	.login-container {
		height: 100%;
		margin-top: 48px;
		background-color: #fff;
		/* padding: 20px 50px 20px 20px; */
		padding: 20px;
	}

	.middle {
		display: table-cell;
		vertical-align: top;
	}

	.login-container .bottom-link-container {
		border-top: 1px solid #dcdcdc;
		margin-top: 1.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0;
	}

	.action {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	:global(.switch-layer) {
		background-color: #ececec;
		color: #161616 !important;
	}

	@media (min-width: 42rem) {
		.login-container {
			margin: auto;
		}
		.middle {
			vertical-align: middle;
		}

		.heading {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			padding-bottom: 1rem;
		}

		:global(.switch-layer):hover {
			background-color: #4c4c4c;
			color: #fff !important;
		}

		/* .login-form .fields-container {
			padding: 1rem;
		} */
	}
</style>

<script>
	// $: title = $TITLE;
	// $: desc = $DESCRIPTION;
	export let title;
	export let desc;
	export let svg;
	export let parent;

	import { onMount } from 'svelte';
	import {
		TERM,
		CLASSROOMS,
		TERMS_ALL,
		CLASSROOMS_ALL,
		CLASSROOMS_IDS,
		ADD_CLASSROOM,
		NETWORK_ERROR
	} from '$lib/stores/stores';
	import { GET_CLASSROOMS } from '$lib/dataproviders/getClassrooms';
	import { GET_TERMS } from '$lib/dataproviders/getTerms';

	import { toastNotify } from '@components/toast';
	import { Select, SelectItem, MultiSelect, Toggle } from 'carbon-components-svelte';
	import { formatSelected, makeLabel } from '$lib/utils/helpers';

	import ReMultiSelect from '@components/ReMultiSelect/index_.svelte';
	import CarbonDropdownClassroom from '../ReMultiSelect/CarbonDropdownClassroom.svelte';

	let optionsClassrooms = [];
	let optionsClassrooms_ = [];
	let optionsTerms = [];

	let classrooms = [];
	let terms = [];
	let placeholder = 'SELECT TERM FIRST';

	let selectedClassrooms = $CLASSROOMS;
	let selectedClassrooms_ = $CLASSROOMS;
	let selectedClassroomsIds = $CLASSROOMS_IDS;
	// let selectedTermId = parseInt($TERM);
	let selectedTermId1 = $TERM > 0 ? [parseInt($TERM)] : [];
	let singleClassroomId;
	let singleTermId;
	let yes = $CLASSROOMS.length > 1 ? true : false;

	// $: selectedClassrooms !== $CLASSROOMS && CLASSROOMS.set(selectedClassrooms);
	// $: selectedClassrooms !== $CLASSROOMS && saveClassroomsIds(selectedClassrooms);
	$: selectedClassrooms_ !== $CLASSROOMS && saveClassroomsIds(selectedClassrooms_);

	// $: selectedClassroomsIds !== $CLASSROOMS_IDS && CLASSROOMS_IDS.set(selectedClassroomsIds);
	$: selectedTermId1[0] !== $TERM && TERM.set(selectedTermId1[0]);

	const makeTermLabel = (arr) => {
		if (arr.length === 1) {
			return arr[0];
		} else {
			return `${arr[0]}`;
		}
	};

	$: {
		if (!yes && selectedClassrooms.length === 1) {
			singleClassroomId = selectedClassrooms[0];
		}
	}

	$: {
		if (!yes && selectedClassrooms.length > 1) {
			let filteredAry = selectedClassrooms.filter((e) => e !== singleClassroomId);
			selectedClassrooms = [filteredAry[0]];
			singleClassroomId = null;
		}
	}

	$: {
		if (selectedTermId1.length === 1) {
			singleTermId = selectedTermId1[0];
		}
	}

	$: {
		if (selectedTermId1.length > 1) {
			let filteredAry = selectedTermId1.filter((e) => e !== singleTermId);
			selectedTermId1 = [filteredAry[0]];
			singleTermId = null;
		}
	}

	$: secondary = formatSelected(selectedClassrooms, optionsClassrooms);
	$: primary = formatSelected(selectedTermId1, optionsTerms);

	// if (Array.isArray($CLASSROOMS) && $CLASSROOMS.length > 0) {
	// 	value = $CLASSROOMS.map((name) => {
	// 		return {
	// 			name: name
	// 		};
	// 	});
	// }

	onMount(() => {
		// selectedClassrooms = $CLASSROOMS;
		if (!parent) {
			getClassRooms();
			getTerms();
		}
	});

	const saveClassroomsIds = async (classrooms) => {
		selectedClassroomsIds = [];
		if ($CLASSROOMS_ALL.length === 0) {
			await getClassRooms();
		}
		classrooms.map((c) => {
			let classroomId = $CLASSROOMS_ALL.filter((o) => o.name.toLowerCase() === c.toLowerCase())[0]
				.id;
			selectedClassroomsIds = [...selectedClassroomsIds, classroomId];
		});
		CLASSROOMS.set(classrooms);
		CLASSROOMS_IDS.set(selectedClassroomsIds);
	};

	const getClassRooms = (termId) => {
		if (!termId) {
			return;
		}
		const callback = async (res) => {
			NETWORK_ERROR.set(false);
			classrooms = [];
			if (res) {
				classrooms = await res.filter((c) => !c.name.includes('undefined'));
				CLASSROOMS_ALL.set(classrooms);
				ADD_CLASSROOM.set(false);
				placeholder = 'SELECT CLASS';
				// options = classrooms.map((a) => {
				// 	let newClassroom = {
				// 		name: a.name
				// 	};
				// 	return newClassroom;
				// });
				const unique = await classrooms.filter(
					(value, index, self) => index === self.findIndex((t) => t.name === value.name)
				);
				optionsClassrooms = await unique.map((a) => {
					let newClassroom = {
						id: a.name,
						text: a.name
					};
					return newClassroom;
				});
				optionsClassrooms_ = await unique.map((a) => {
					let newClassroom = {
						id: a.id,
						name: a.name
					};
					return newClassroom;
				});
			}
		};
		const onError = (error) => {
			if (JSON.stringify(error).includes('timeout') && JSON.stringify(error).includes('exceeded')) {
				toastNotify('Please Check Your Internet Connection !', 'error');
				NETWORK_ERROR.set(true);
			} else if (JSON.stringify(error).includes('Network Error')) {
				toastNotify('Please Check Your Internet Connection !', 'error');
			}
		};
		GET_CLASSROOMS(callback, onError, termId);
	};

	const getTerms = async () => {
		const callback = async (res) => {
			terms = [];
			if (res) {
				const tempTerms = await res;
				TERMS_ALL.set([...tempTerms]);
				terms = await tempTerms.sort((a, b) => b.id - a.id);
				optionsTerms = terms.map((a) => {
					let newTerm = {
						id: a.id,
						text: `TERM ${a.name} OF ${a.session} SESSION`
					};
					return newTerm;
				});
			}
		};
		const onError = (error) => {};
		GET_TERMS(callback, onError);
	};

	$: getClassRooms($TERM, $ADD_CLASSROOM);
	$: $TERM && getClassRooms($TERM);
</script>

<div class="row bg-white content-body-title">
	<div class="title_desc">
		{#if svg}
			{@html svg}
		{/if}
		<div style="margin-left: 15px;" class:mt-2={parent}>
			<h4 style="margin:0">{title && `${title} Module`}</h4>
			<span>{desc && `${desc}.`}</span>
		</div>
	</div>
	{#if !parent}
		<div class="header-wrap">
			<div class="header-tools">
				<ul class="quick-nav">
					<li
						class=""
						style="display:flex;
				align-items: center;"
					>
						<!-- <Toggle
							style="padding: 0 5px"
							labelA="No"
							labelB="Yes"
							size="sm"
							labelText="Multiple"
							class="form-toggle-1"
							bind:toggled={yes}
						/> -->
						<div class="form-group-1">
							{#await optionsClassrooms_}
								{''}
							{:then items}
								<!-- <MultiSelect
									size="md"
									bind:selectedIds={selectedClassrooms}
									label={secondary ? makeLabel(secondary) : 'SELECT CLASS'}
									{items}
									sortItem={(a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0)}
								/> -->
								<!-- <ReMultiSelect
									size="md"
									bind:selectedIds={selectedClassrooms}
									label={secondary ? makeLabel(secondary) : 'SELECT CLASS'}
									{items}
									sortItem={(a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0)}
								/> -->
								<CarbonDropdownClassroom
									bind:selectedClassrooms={selectedClassrooms_}
									multiple={selectedClassrooms.length > 1 ? true : false}
									{placeholder}
								/>
							{/await}
						</div>
					</li>
					<li class="">
						<div class="form-group-1 term">
							<!-- <Select
							class="select-box"
							light
							bind:selected={selectedTermId}
							on:change={() => TERM.set(selectedTermId)}
						>
							<SelectItem value={''} text="SELECT TERM" />
							{#if terms}
								{#each terms as term}
									<SelectItem
										value={term.id}
										text={`TERM ${term.name} OF ${term.session} SESSION`}
									/>
								{/each}
							{/if}
						</Select> -->
							<div
								style="width: 320px;
				"
							/>
							{#await optionsTerms}
								{''}
							{:then items}
								<MultiSelect
									size="md"
									bind:selectedIds={selectedTermId1}
									label={primary ? makeTermLabel(primary) : 'SELECT TERM'}
									{items}
									sortItem={() => {}}
									selectionFeedback="fixed"
								/>
							{/await}
						</div>
					</li>
					<!-- .dropdown -->
				</ul>
				<!-- .nk-quick-nav -->
			</div>
		</div>
	{/if}
</div>

<style>
	/* Select Styles */
	:global(.form-group-1 .select-box > .bx--select) {
		flex-direction: row;
	}
	:global(.form-group-1 .select-box > .bx--select--light .bx--select-input) {
		background-color: #0f62fe;
		color: #fff;
	}
	:global(.form-group-1 .select-box > .bx--select--light .bx--select-input):hover {
		color: #000;
		background-color: #e5e5e5;
	}

	/* MultiSelect Styles */
	:global(.form-group-1 .bx--list-box__field) {
		background-color: #0f62fe;
		color: #fff !important;
		border-bottom: 1px solid rgb(36, 36, 36);
	}

	:global(.form-group-1 .bx--list-box__menu) {
		background-color: #ffffff !important	;
	}

	:global(.form-group-1 .bx--checkbox-label-text) {
		font-weight: 400 !important;
	}

	:global(.form-group-1 .bx--list-box__menu-item):hover,
	:global(.bx--list-box__menu-item--active),
	:global(.bx--list-box__menu-item .bx--list-box__menu-item--highlighted),
	:global(.bx--list-box__menu-item--active.bx--list-box__menu-item--highlighted) {
		background-color: inherit;
	}

	:global(.form-group-1 .bx--list-box__field):focus {
		outline: none;
	}

	:global(.form-group-1 .bx--tag--high-contrast) {
		color: #000;
		background-color: #fff;
	}

	:global(.form-group-1.term .bx--tag--high-contrast) {
		display: none;
	}

	:global(.form-group-1 .bx--list-box__field):hover {
		background-color: #e5e5e5;
		color: #000 !important;
	}
	:global(.bx--list-box__label) {
		color: inherit !important;
	}

	:global(.form-group-1 .bx--list-box--expanded .bx--list-box__menu) {
		max-height: 23rem;
	}

	:global(.form-group-1 .bx--list-box__field:hover .bx--list-box__menu-icon > svg) {
		fill: #000;
	}

	:global(.form-group-1 .bx--list-box__field .bx--list-box__menu-icon > svg) {
		fill: #fff;
	}

	:global(.form-group-1 .bx--list-box__label) {
		/* font-weight: 600 !important;
		font-size: 0.875rem !important; */

		font-weight: 500 !important;
		font-size: 0.775rem !important;
	}

	:global(.form-toggle-1 .bx--toggle-input__label) {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0px !important;
	}

	:global(.form-toggle-1 .bx--toggle__switch) {
		margin-top: 0px !important;
		margin-left: 5px;
	}

	.title_desc {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	/* :global(.form-group-1 .term .bx--tag .bx--tag--filter .bx--tag--high-contrast) {
		display: none !important;
	} */
</style>

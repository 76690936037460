import LogRocket from 'logrocket';

export function initLogRocket(logrocket_id, user = null) {
	if (typeof window !== 'undefined') {
		LogRocket.init(logrocket_id);

		// Add additional context if needed
		if (user && user?.username && user?.user_id) {
			LogRocket.identify(user.user_id, {
				name: user.fullname,
				username: user.username,
				user_type: user.school[0].schoolname ?? '',
				others: user.user_type
			});
		}
	}

	return LogRocket;
}

// Capture console errors
export function setupErrorTracking() {
	if (typeof window !== 'undefined') {
		// Capture uncaught errors
		window.addEventListener('error', (event) => {
			LogRocket.captureException(event.error);
		});

		// Capture unhandled promise rejections
		window.addEventListener('unhandledrejection', (event) => {
			LogRocket.captureException(event.reason);
		});

		// Override console.error to capture errors
		const originalConsoleError = console.error;
		console.error = (...args) => {
			LogRocket.captureException(
				args.length === 1 && args[0] instanceof Error ? args[0] : new Error(args.join(' '))
			);
			originalConsoleError.apply(console, args);
		};
	}
}

<script>
	export let data;

	import Navbar from '@components/Navbar/index.svelte';
	import Sidebar from '@components/Sidebar/index.svelte';
	import Titlebar from '@components/Titlebar/index.svelte';
	import Login from '@components/Login/index.svelte';
	import NotificationMessage from '@components/NotificationMessage/index.svelte';
	import { apiErrorHandler } from '$lib/utils/apiErrorHandler';
	import { page } from '$app/stores';
	import {
		TERM,
		CLASSROOMS,
		STUDENTS,
		TERM_DETAILS,
		ADD_STUDENT,
		USER_DETAILS,
		UNIVERSAL_SUCCESS_MESSAGE,
		TOAST_N,
		CURRENT_URL,
		SEARCH_MODE,
		LOGIN_TYPE
	} from '$lib/stores/stores';
	import { GET_STUDENTS } from '$lib/dataproviders/getStudents';
	import { toTitleCase, info, arrayIsNotEqual, checkUserAgentasMobile } from '$lib/utils/helpers';
	import { openSidebar, closeSidebar } from '$lib/utils/theme';
	import { onMount } from 'svelte';
	import { toastNotify } from '@components/toast';
	import { GET_TERM } from '$lib/dataproviders/getTerms';
	import { PARENT_INFO } from '$lib/stores/parent';
	import { initLogRocket, setupErrorTracking } from '$lib/utils/logrocket';
	import Error from './+error.svelte';
	import '$lib/styles/style.css';

	// title desc array
	let titleDesc = { title: '', desc: '' };

	onMount(async () => {
		// initialize logrocket
		if (import.meta.env.VITE_LOGROCKET_ID) {
			await initLogRocket(import.meta.env.VITE_LOGROCKET_ID, $USER_DETAILS);

			// setup error tracking
			await setupErrorTracking();

			/* LogRocket.getSessionURL((sessionURL) => {
				Sentry.configureScope((scope) => {
					scope.setExtra('sessionURL', sessionURL);
				});
			}); */
		}

		if (!$USER_DETAILS) {
			if ($page.url.pathname.includes('parent')) {
				LOGIN_TYPE.set('parent');
			} else {
				LOGIN_TYPE.set('staff');
			}
			// if (data.showError) {
			// 	UNIVERSAL_ERROR_MESSAGE.set('Login Required!');
			// }
		}

		// sidebar collapse
		closeSidebar();

		window.$('#sidebarCollapse').on('click', function () {
			openSidebar();
		});
		// window.$('#sidebarReturn, .overlay').on('click', function () {
		// 	closeSidebar();
		// });
	});
	let tempTerm = $TERM;
	let tempClassrooms = $CLASSROOMS || [];

	const parent = data?.user_type?.toLowerCase() === 'parent';

	const getStudentsAfterAdd = async () => {
		await getStudents($TERM, $CLASSROOMS);
		ADD_STUDENT.set(false);
	};

	const getParentStudents = async () => {
		$PARENT_INFO?.students && $PARENT_INFO?.students.length > 0
			? await STUDENTS.set($USER_DETAILS?.students)
			: await STUDENTS.set([]);
	};

	let msg = '';
	const getStudents = async (term, classrooms) => {
		if (!term || classrooms.length === 0 || data.isLoggedIn === false) {
			return;
		}
		if ($page.url.pathname.includes('/reports/verification')) {
			return;
		}
		await STUDENTS.set([]);
		const callback = async (res, message) => {
			if (res) {
				const unique = res.filter(
					(value, index, self) => index === self.findIndex((t) => t.studentid === value.studentid)
				);
				await STUDENTS.set(unique);
				// clear search mode
				SEARCH_MODE.set(false);
				// prevents multiple messages
				// only displays on term or classroom change
				// only displays on students page
				if (
					$page.url.pathname.includes('/students') &&
					(msg !== message || tempTerm !== $TERM || tempClassrooms !== $CLASSROOMS)
				) {
					toastNotify(toTitleCase(message), 'success');
					msg = message;
				}
			}
		};
		const onError = async (error) => {
			await STUDENTS.set([]);
		};
		GET_STUDENTS(term, classrooms, $USER_DETAILS?.school[0]?.schoolid, callback, onError);
	};

	const getTerm = async (term_id) => {
		if (!term_id || data.isLoggedIn === false) {
			return;
		}
		const callback = async (res, message) => {
			if (res) {
				await TERM_DETAILS.set(res[0]);
			}
		};
		const onError = (error) => {
			apiErrorHandler(error);
		};
		GET_TERM(term_id, callback, onError);
	};

	const getPageTitleandDesc = (path) => {
		if (path !== '/') {
			titleDesc = info.find((o) => o.page === path.split('/')[1]);
		}
	};

	// get page title and description from page url
	$: !$page.error && getPageTitleandDesc($page.url.pathname);
	// store current url for re-login purposes
	$: {
		if ($page.url.pathname) {
			CURRENT_URL.set($page.url.pathname);
		}
	}

	$: if (tempTerm !== $TERM && parent === false) {
		getStudents($TERM, $CLASSROOMS);
		getTerm($TERM);
		tempTerm = $TERM;
	}
	$: if (arrayIsNotEqual(tempClassrooms, $CLASSROOMS) && parent === false) {
		getStudents($TERM, $CLASSROOMS);
		tempClassrooms = $CLASSROOMS;
	}
	$: $USER_DETAILS && parent === false && $ADD_STUDENT && getStudentsAfterAdd($ADD_STUDENT);

	$: $USER_DETAILS && parent === true && getParentStudents();

	$: {
		if ($UNIVERSAL_SUCCESS_MESSAGE !== '') {
			toastNotify($UNIVERSAL_SUCCESS_MESSAGE, 'success');
			UNIVERSAL_SUCCESS_MESSAGE.set('');
		}
	}
</script>

{#if $TOAST_N}
	<NotificationMessage
		showNotifier={true}
		kind={$TOAST_N.kind}
		title={$TOAST_N.title}
		message={$TOAST_N.message}
	/>
{/if}

{#if $page.error}
	<Error />
{:else if data.isLoggedIn}
	{#if $page.url.pathname.includes('/reports/verification') || (checkUserAgentasMobile() && !parent) || $page.url.pathname.includes('/visualize-report') || $page.url.pathname.includes('/report-comments')}
		<slot />
	{:else}
		<div class="wrapper">
			<Sidebar path={$page.url.pathname} {parent} />
			<!-- Page Content  -->
			<div id="content">
				<Navbar {parent} />
				<Titlebar title={titleDesc.title} desc={titleDesc.desc} svg={titleDesc.svg} {parent} />
				<slot path={$page.url.pathname} />
			</div>
			<!-- Dark Overlay element -->
			<div class="overlay" />
		</div>
	{/if}
{:else if $page.url.pathname.includes('/parents') && !data.isLoggedIn}
	<Login returnUrl={data.route} currentUrl={data.current_url} userType="parent" />
{:else}
	<Login returnUrl={data.route} currentUrl={data.current_url} userType="staff" />
{/if}

<style>
	:global(#content > .container-fluid) {
		margin-bottom: 1vh;
		padding-left: 5px;
	}

	:global(.bx--tab-content, .card-body, .bx--grid) {
		padding: 0px;
	}
	:global(
			.bx--col-md-2,
			.bx--col-md-3,
			.bx--col-md-4,
			.bx--col-md-5,
			.bx--col-md-6,
			.bx--col-md-7,
			.bx--col-md-8
		) {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}
	:global(.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text) {
		font-size: small;
		padding-left: 4px;
		padding-right: 4px;
	}
	:global(.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text) {
		display: none;
	}
	:global(.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::before) {
		display: none;
	}

	:global(.bx--text-input:disabled, .bx--select-input:disabled, .bx--select-input:hover:disabled) {
		color: #000;
		-webkit-text-fill-color: #000;
	}
	:global(.bx--text-input:disabled, .bx--select-input:disabled, .bx--label--disabled) {
		color: #000;
		-webkit-text-fill-color: #000;
	}

	/* Handsontable */
	:global(.handsontable thead th) {
		font-weight: bold;
	}

	:global(.bx--search-close) {
		margin-top: 5px;
	}

	:global(.bx--search-close):focus::before {
		background-color: inherit;
	}

	:global(.handsontable tr) {
		font-size: 12px;
	}

	:global(.table-title) {
		color: #1a5cbe;
		font-weight: bolder;
	}

	/* modulw list buttons */
	:global(.bx--btn--tertiary) {
		border-color: #eeeeee;
		color: #47525d;
		/* margin-left: 1px; */
		background-color: #eeeeee;
	}
	:global(.bx--btn--tertiary:hover) {
		background-color: #47525d;
	}

	:global(.bx--btn--tertiary:focus) {
		background-color: #47525d;
		box-shadow: inset 0 0 0 1px #47525d, inset 0 0 0 2px #fff;
	}

	:global(.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus) {
		border-color: #47525d;
	}

	/* :global(.custom-table .handsontable .htRowHeaders .htColumnHeaders) {
		padding-bottom: 0px;
		margin-bottom: 0px;
		max-height: 59vh;
	}

	:global(.ht_master .handsontable > .wtHolder) {
		max-height: 364.016px;
	} */

	:global(.non-label) {
		display: flex;
		justify-content: flex-end;
		padding: 1rem;
		color: #606569 !important;
		text-overflow: initial !important;
		font-weight: bolder !important;
		border-bottom: none !important;
		font-size: 105% !important;
		padding: 0.75rem 0.5rem 0.5rem;
	}

	:global(.non-label .title) {
		color: #000 !important;
	}

	/* MultiSelect */
	:global(.multi-select .bx--list-box--expanded .bx--list-box__menu) {
		max-height: 15rem;
	}

	/* Modal Styles */
	/* :global(.bx--modal-container) {
		width: 25vw;
		margin-top: 15vh;
	} */

	:global(.card) {
		border: none;
	}

	/* Button Styles */
	:global(.btn-sizing) {
		min-height: 2rem !important;
		padding: calc(0.875rem - 3px) 50px calc(0.875rem - 3px) 15px;
	}

	/* Analysis and comments */

	:global(.special-select) {
		height: 100%;
	}
	:global(.special-select .bx--multi-select--filterable .bx--tag) {
		display: none;
	}
	:global(.special-select input::-webkit-input-placeholder) {
		color: #000;
	}

	:global(
			.special-select .bx--multi-select--filterable.bx--multi-select--selected .bx--text-input
		) {
		padding-left: 15px;
	}

	:global(.special-select input:-moz-placeholder) {
		color: #000;
	}
</style>

<script>
	export let path;
	import { onMount } from 'svelte';
	import logo from '$lib/images/schoolmo.png';
	import schoolMo from '$lib/images/schoolmo3.png';
	import Home from 'carbon-icons-svelte/lib/Home.svelte';
	import IbmCloudDirectLink_1DedicatedHosting from 'carbon-icons-svelte/lib/IbmCloudDirectLink_1DedicatedHosting.svelte';
	import User from 'carbon-icons-svelte/lib/User.svelte';
	import UserAdmin from 'carbon-icons-svelte/lib/UserAdmin.svelte';
	import Education from 'carbon-icons-svelte/lib/Education.svelte';
	import Layers from 'carbon-icons-svelte/lib/Layers.svelte';
	import GroupPresentation from 'carbon-icons-svelte/lib/GroupPresentation.svelte';
	import CrossTab from 'carbon-icons-svelte/lib/CrossTab.svelte';
	import DocumentComment from 'carbon-icons-svelte/lib/DocumentComment.svelte';
	import Catalog from 'carbon-icons-svelte/lib/Catalog.svelte';
	import Archive from 'carbon-icons-svelte/lib/Archive.svelte';
	import TextLinkAnalysis from 'carbon-icons-svelte/lib/TextLinkAnalysis.svelte';
	import Document from 'carbon-icons-svelte/lib/Document.svelte';
	import MediaLibrary from 'carbon-icons-svelte/lib/MediaLibrary.svelte';
	import CloudMonitoring from 'carbon-icons-svelte/lib/CloudMonitoring.svelte';
	import Money from 'carbon-icons-svelte/lib/Money.svelte';
	import UserAvatarFilledAlt from 'carbon-icons-svelte/lib/UserAvatarFilledAlt.svelte';
	import Report from 'carbon-icons-svelte/lib/Report.svelte';
	import Finance from 'carbon-icons-svelte/lib/Finance.svelte';
	import { closeSidebar } from '$lib/utils/theme';
	import Close from 'carbon-icons-svelte/lib/Close.svelte';

	import {
		CLASSROOMS,
		STUDENTS_TOTAL,
		TERM_DETAILS,
		TOKEN,
		USER_DETAILS
	} from '$lib/stores/stores';
	import { GET_LOGO } from '$lib/dataproviders/getSettings';
	import { PARENT_INFO } from '$lib/stores/parent';
	import { api as API } from '$lib/dataproviders/connector';
	import { OutboundLink } from 'carbon-components-svelte';

	export let parent;
	// console.log('parent_sidebar______', parent);

	// const API = 'https://schoolmo-backend.onrender.com/api';
	// const API = 'https://schoolmo-backend-tvni.onrender.com/api';
	// const API = 'https://schoolmo-backend.up.railway.app/api';
	let currentToken = TOKEN;

	onMount(() => {
		window.$('#sidebar').mCustomScrollbar({
			theme: 'minimal'
		});
		currentToken = localStorage.getItem('token');
	});

	const supportUsers = import.meta.env.VITE_SUPPORT_USERS.split(',');
</script>

<!-- Sidebar  -->
<nav id="sidebar" class="sidebar-nav">
	<div class="sidebar-header">
		<a
			href={$USER_DETAILS.user_type.toLowerCase() !== 'parent' ? '/dashboard' : '/parents/students'}
		>
			<img src={schoolMo} srcset={schoolMo} alt="schoolmo" style="max-height:40px" />
		</a>
		<!-- <span id="sidebarReturn">
			<h5><i class="material-icons">arrow_back</i></h5>
		</span> -->
	</div>
	<div style="padding:0.5rem 30px .5rem 20px;display: flex;justify-content: center;">
		{#await GET_LOGO() then logo}
			{#if logo}
				<img src={logo} alt="school_logo" style="max-height:150px;" />
			{/if}
		{/await}
	</div>
	<!-- svelte-ignore a11y-invalid-attribute -->
	<ul class="list-unstyled components">
		{#if !parent}
			<li class:active={path.includes('/dashboard') && !path.includes('/finance')}>
				<a href="/dashboard"><Home size={20} class="custom-class" />Dashboard</a>
			</li>
			<li class:active={path.startsWith('/students')}>
				<a href="/students">
					<Education size={20} class="custom-class" />
					{`Students [${$STUDENTS_TOTAL ? $STUDENTS_TOTAL : ''}]`}
				</a>
			</li>
			<li class:active={path.includes('/classrooms')}>
				<a href="/classrooms"><GroupPresentation size={20} class="custom-class" />Classrooms</a>
			</li>
			<li class:active={path.includes('/teachers')}>
				<a href="/teachers"><User size={20} class="custom-class" />Teachers</a>
			</li>
			<li class:active={path.includes('/subjects')}>
				<a href="/subjects"><Catalog size={20} class="custom-class" />Subjects</a>
			</li>
			<li class:active={path.includes('/scoresheet')}>
				<a href="/scoresheet"><CrossTab size={20} class="custom-class" />Scoresheet</a>
			</li>
			<!-- {#if supportUsers.includes($USER_DETAILS.username)} -->
			<li class:active={path.includes('/subject-comments')}>
				<a href="/subject-comments">
					<DocumentComment size={20} class="custom-class" />Subject Comments
				</a>
			</li>
			<!-- {/if} -->
			<li class:active={path.includes('/non-academics')}>
				<a href="/non-academics"><Archive size={20} class="custom-class" />Non - Academics</a>
			</li>
			<li class:active={path.includes('/broadsheet')}>
				<a href="/broadsheet"><Document size={20} class="custom-class" />Broadsheet</a>
			</li>
			<li class:active={path.includes('/e-learning')}>
				<a href="/e-learning"><MediaLibrary size={20} class="custom-class" />E- Learning</a>
			</li>
			<li class:active={path.includes('/analysis')}>
				<a href="/analysis"><TextLinkAnalysis size={20} class="custom-class" />Analysis</a>
			</li>
			<li class:active={path.includes('/cbt')}>
				<a href="#cbt"><CloudMonitoring size={20} class="custom-class" />CBT</a>
			</li>
			<li class:active={path.includes('/users')}>
				<a href="/users"><UserAdmin size={20} class="custom-class" />Users</a>
			</li>
			<li class:active={path.includes('/staff')}>
				<a href="/staff"><UserAvatarFilledAlt size={20} class="custom-class" />Staff</a>
			</li>
			<!-- <li class="has-menu" class:active={path.includes('/staff')}>
				<a
					href="#staffSubmenu"
					data-toggle="collapse"
					aria-expanded="false"
					class="dropdown-toggle"
				>
					<UserAvatarFilledAlt size={20} class="custom-class" />Staffs</a
				>
				<ul class="collapse list-unstyled menu-sub" id="staffSubmenu">
					<li class:active={path === '/staff'}>
						<a href="/staff" class="menu-link">Staff Profile</a>
					</li>
					<li class:active={path === '/staff/new'}>
						<a href="/staff/new" class="menu-link">New Staff Profile</a>
					</li>
				</ul>
			</li> -->
			<li class="has-menu" class:active={path.includes('/reports')}>
				<a
					href="#reportSubmenu"
					data-toggle="collapse"
					aria-expanded="false"
					class="dropdown-toggle"
				>
					<Report size={20} class="custom-class" />Reports</a
				>
				{#if $CLASSROOMS && $TERM_DETAILS && $TOKEN?.token}
					<ul class="collapse list-unstyled menu-sub" id="reportSubmenu">
						<!-- {#if supportUsers.includes($USER_DETAILS.username)} -->
						<li class:active={path.includes('/report-comments')}>
							<!-- <a href="/report-comments" target="_blank" class="menu-link"> Report Comments </a> -->
							<OutboundLink href="/report-comments" class="menu-link">Report Comments</OutboundLink>
						</li>
						<!-- {/if} -->
						<li class:active={path.includes('/reports/verification')}>
							<a
								href={`/reports/verification?classroom=${$CLASSROOMS.join("', '")}&term=${
									$TERM_DETAILS.name
								}&session=${$TERM_DETAILS.session}`}
								class="menu-link"
								target="_blank"
								rel="noreferrer"
							>
								Verification
							</a>
						</li>
						<li class:active={path === '/reports/half-term'}>
							<a
								href={`${API}/report/half-term?classroom=${$CLASSROOMS.join("', '")}&term=${
									$TERM_DETAILS.name
								}&session=${$TERM_DETAILS.session}&token=${$TOKEN.token.replaceAll(
									'"',
									''
								)}&school_id=${$USER_DETAILS?.school[0]?.schoolid}`}
								class="menu-link"
								external
								target="_blank"
								rel="noreferrer"
							>
								Half Term Report
							</a>
						</li>
						<li class:active={path === '/reports/full-term'}>
							<a
								href={`${API}/report/full-term?classroom=${$CLASSROOMS.join("', '")}&term=${
									$TERM_DETAILS.name
								}&session=${$TERM_DETAILS.session}&token=${$TOKEN.token.replaceAll(
									'"',
									''
								)}&school_id=${$USER_DETAILS?.school[0]?.schoolid}`}
								class="menu-link"
								external
								target="_blank"
								rel="noreferrer"
							>
								Full Term Report
							</a>
						</li>
						<li class:active={path === '/reports/full-term'}>
							<a
								href={`${API}/report/non-academics?classroom=${$CLASSROOMS.join("', '")}&term=${
									$TERM_DETAILS.name
								}&session=${$TERM_DETAILS.session}&token=${$TOKEN.token.replaceAll(
									'"',
									''
								)}&school_id=${$USER_DETAILS?.school[0]?.schoolid}`}
								class="menu-link"
								external
								target="_blank"
								rel="noreferrer"
							>
								Non Academics Report
							</a>
						</li>
						<li class:active={path === '/reports/promotion-repeated-list'}>
							<a href="/reports/promotion-repeated-list" class="menu-link">
								Promotion and Repeat List
							</a>
						</li>
					</ul>
				{/if}
			</li>
			<li class="has-menu" class:active={path.includes('/finance')}>
				<a
					href="#financeSubmenu"
					data-toggle="collapse"
					aria-expanded="false"
					class="dropdown-toggle"
				>
					<Finance size={20} class="custom-class" />
					Finance
				</a>
				<ul class="collapse list-unstyled menu-sub" id="financeSubmenu">
					<li class:active={path === '/finance/dashboard'}>
						<a href="/finance/dashboard" class="menu-link">Dashboard</a>
					</li>
					<li class:active={path.includes('/finance/fee-schedule')}>
						<a href="/finance/fee-schedule" class="menu-link">Fee Schedule</a>
					</li>
					<li class:active={path === '/finance/student-fees'}>
						<a href="/finance/student-fees" class="menu-link">Student Fees</a>
					</li>
					<li class:active={path === '/finance/split-fees'}>
						<a href="/finance/split-fees" class="menu-link">Split Fees</a>
					</li>
					<li class:active={path.includes('/finance/transaction-history')}>
						<a href="/finance/transaction-history" class="menu-link">Transaction History</a>
					</li>
					<li class:active={path === '/finance/income'}>
						<a href="/finance/income" class="menu-link">Income</a>
					</li>
					<li class:active={path === '/finance/outstanding'}>
						<a href="/finance/outstanding" class="menu-link">Outstanding</a>
					</li>
					<li class:active={path === '/finance/students-accounts'}>
						<a href="/finance/students-accounts" class="menu-link">Students Accounts</a>
					</li>
				</ul>
			</li>
			{#if supportUsers.includes($USER_DETAILS.username)}
				<li class:active={path.includes('/activity-logs')}>
					<a href="/activity-logs">
						<IbmCloudDirectLink_1DedicatedHosting size={20} class="custom-class" />
						Activity Logs
					</a>
				</li>
			{/if}
		{:else}
			<li class:active={path.includes('/parents/students')}>
				<a href="/parents/students">
					<Education size={20} class="custom-class" />
					{`Students [${$PARENT_INFO?.students ? $PARENT_INFO.students.length : ''}]`}
				</a>
			</li>

			<!-- <li class:active={path.includes('/parents/split-fees')}>
				<a href="/parents/split-fees">
					<Money size={20} class="custom-class" />
					{`Split Fees`}
				</a>
			</li> -->
		{/if}
	</ul>

	<!-- svelte-ignore a11y-invalid-attribute -->
	<a
		href="#"
		class="sidebar-close"
		on:click={(e) => {
			e.preventDefault();
			closeSidebar();
		}}
	>
		<Close size={20} class="sidebar-close" on:click={() => closeSidebar()} />
	</a>
</nav>

<style>
	:global(svg.custom-class) {
		fill: #fff;
	}

	:global(.sidebar-close) {
		position: absolute;
		display: none;
		top: 0px;
		right: 4px;
		cursor: pointer;
		/* color: #4169e1; */
	}

	.sidebar-nav {
		position: relative;
	}

	@media (max-width: 768px) {
		:global(.sidebar-nav) {
			padding-top: 4px;
		}

		:global(.sidebar-close) {
			display: block;
		}
		:global(.sidebar-header) {
			padding-top: 16px !important;
		}
	}
</style>

<script>
	import { Timer, _time_diff } from '$lib/utils/helpers';
	import { TOAST_N } from '$lib/stores/stores';

	import { ToastNotification } from 'carbon-components-svelte';
	import { onMount } from 'svelte';

	export let kind = '';
	export let title = '';
	export let message = '';
	export let showNotifier;

	let timer_ = null;
	let timerID = null;
	let total_time_run = 0;
	let start_time;
	let complete;
	let countdown = 4500;
	let mouseLeaveTimerID = null;

	const onMouseEnter = () => {
		clearTimeout(mouseLeaveTimerID);
		clearTimeout(timerID);
		total_time_run = _time_diff(start_time);
		complete = total_time_run >= countdown;

		// timer_.pause();
	};

	const onMouseLeave = () => {
		mouseLeaveTimerID = setTimeout(() => {
			if (complete) {
				showNotifier = false;
				TOAST_N.set(null);
			} else {
				timerID = setTimeout(() => {
					showNotifier = false;
					TOAST_N.set(null);
				}, countdown - total_time_run);
			}
		}, 500);

		// timer_.resume();
	};

	onMount(() => {
		start_time = new Date().getTime();
		timerID = setTimeout(() => {
			showNotifier = false;
			TOAST_N.set(null);
		}, countdown);
	});

	// $: console.log(showNotifier);
</script>

{#if showNotifier}
	<div class="topcorner">
		<div class="sticky-div">
			<ToastNotification
				lowContrast={false}
				{kind}
				{title}
				subtitle={message}
				class="special-toast"
				on:close={() => {
					TOAST_N.set(null);
				}}
				on:mouseenter={() => onMouseEnter()}
				on:mouseleave={() => onMouseLeave()}
				on:focus={() => onMouseEnter()}
				on:blur={() => onMouseLeave()}
			/>
		</div>
	</div>
{/if}

<style>
	.topcorner {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9999;

		/* background-color: red;
  height: 50px;
  width: 200px; */
	}

	.sticky-div {
		position: sticky;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	:global(.special-toast .bx--toast-notification__caption) {
		display: none;
	}

	:global(.special-toast .bx--toast-notification__title) {
		color: #fff;
	}
</style>
